// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-js": () => import("./../src/pages/join.js" /* webpackChunkName: "component---src-pages-join-js" */),
  "component---src-pages-podcasts-js": () => import("./../src/pages/podcasts.js" /* webpackChunkName: "component---src-pages-podcasts-js" */),
  "component---src-pages-privacy-js": () => import("./../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-visualizations-js": () => import("./../src/pages/visualizations.js" /* webpackChunkName: "component---src-pages-visualizations-js" */),
  "component---src-pages-visualizations-risk-score-vis-js": () => import("./../src/pages/visualizations/risk-score-vis.js" /* webpackChunkName: "component---src-pages-visualizations-risk-score-vis-js" */),
  "component---src-pages-visualizations-us-rates-interactive-js": () => import("./../src/pages/visualizations/us-rates-interactive.js" /* webpackChunkName: "component---src-pages-visualizations-us-rates-interactive-js" */),
  "component---src-templates-project-post-js": () => import("./../src/templates/project-post.js" /* webpackChunkName: "component---src-templates-project-post-js" */)
}

